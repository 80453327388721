@import '@/styles/base-imports';

.container {
    cursor: pointer;
    border-width: 1px;
    color: hsl(0, 0%, 21%);
    justify-content: center;
    padding-block: calc(0.5rem - 1px);
    padding-inline: 1rem;
    text-align: center;
    white-space: nowrap;
    user-select: none;
    appearance: none;
    align-items: center;
    border: 1px solid transparent;
    border-radius: 4px;
    box-shadow: none;
    display: inline-flex;
    font-size: 1rem;
    height: 2.5em;
    line-height: 1.5;
    position: relative;
    vertical-align: top;
    margin-block: .5rem;

    &.primary {
        color: white;
        background-color: $primary;
    }

    &.secondary {
        background: transparent;
        border-color: $secondary;
        color: $secondary;

        &:hover {
            background-color: $secondary;
            color: white;
        }
    }
}